import { MaterialIcon, MaterialIconName } from '..';
import { Btn, ButtonTypes, Props } from '../btn';
import * as React from 'react';
import './styles.scss';

const baseClass = 'pn-collapse-button';
export const CollapseBtn: React.FC<
  Pick<Props, 'action'> & {
    readonly ariaControls: string;
    readonly expanded: boolean;
    readonly className?: string;
    readonly iconArgs?: {
      readonly expanded?: MaterialIconName;
      readonly collased?: MaterialIconName;
    };
  }
> = (p) => (
  <div
    className={`${baseClass}-expand-container ${p.className || ''} no-show-in-image`}
  >
    <Btn
      action={p.action}
      ariaControls={p.ariaControls}
      ariaExpanded={p.expanded}
      ariaLabel={p.expanded ? 'Collapse banner' : 'Uncollapse banner'}
      className={`${baseClass}-expand-container-button`}
      customPadding="0px"
      type={ButtonTypes.SEAMLESS}
    >
      <MaterialIcon
        className={`${baseClass}-expand-container-button-icon`}
        icon={
          p.expanded
            ? p.iconArgs?.expanded ?? MaterialIconName.UNFOLD_LESS
            : p.iconArgs?.collased ?? MaterialIconName.UNFOLD_MORE
        }
      />
    </Btn>
  </div>
);
