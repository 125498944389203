import { FeatureSettingType } from './feature-setting';

export enum ShareableContentDomains {
  GENERAL = 'GENERAL',
  ECONOMY = 'ECONOMY',
  EDUCATION_ARTS_CULTURE = 'EDUCATION_ARTS_CULTURE',
  GOVERNANCE = 'GOVERNANCE',
  INCLUSIVITY_ENGAGEMENT = 'INCLUSIVITY_ENGAGEMENT',
  SAFETY = 'SAFETY',
  // Infrastructure/land use
  MOBILITY = 'MOBILITY',
  COMMUNITY_DESIGN = 'COMMUNITY_DESIGN',
  UTILITIES = 'UTILITIES',
  // Community Health
  NATURAL_ENVIRONMENT = 'NATURAL_ENVIRONMENT',
  PARKS_RECREATION = 'PARKS_RECREATION',
  HEALTH_WELLNESS = 'HEALTH_WELLNESS',
}

export const libraryDomainLabels: Record<ShareableContentDomains, string> = {
  GOVERNANCE: 'Governance',
  SAFETY: 'Safety',
  ECONOMY: 'Economy',
  EDUCATION_ARTS_CULTURE: 'Education, Arts & Culture',
  GENERAL: 'General',
  MOBILITY: 'Mobility',
  COMMUNITY_DESIGN: 'Community Design',
  INCLUSIVITY_ENGAGEMENT: 'Inclusivity & Engagement',
  HEALTH_WELLNESS: 'Health & Wellness',
  UTILITIES: 'Utilities',
  PARKS_RECREATION: 'Parks & Recreation',
  NATURAL_ENVIRONMENT: 'Natural Environment',
};

export const ShareableContentDomainsFeatureSettings: Record<
  ShareableContentDomains,
  FeatureSettingType
> = {
  [ShareableContentDomains.GOVERNANCE]: FeatureSettingType.LIBRARY_GOVERNANCE,
  [ShareableContentDomains.SAFETY]: FeatureSettingType.LIBRARY_SAFETY,
  [ShareableContentDomains.ECONOMY]: FeatureSettingType.LIBRARY_ECONOMY,
  [ShareableContentDomains.EDUCATION_ARTS_CULTURE]:
    FeatureSettingType.LIBRARY_EDUCATION_ARTS_AND_CULTURE,
  [ShareableContentDomains.GENERAL]: FeatureSettingType.LIBRARY_GENERAL,
  [ShareableContentDomains.MOBILITY]: FeatureSettingType.LIBRARY_MOBILITY,
  [ShareableContentDomains.COMMUNITY_DESIGN]:
    FeatureSettingType.LIBRARY_COMMUNITY_DESIGN,
  [ShareableContentDomains.INCLUSIVITY_ENGAGEMENT]:
    FeatureSettingType.LIBRARY_INCLUSIVITY_ENGAGEMENT,
  [ShareableContentDomains.HEALTH_WELLNESS]:
    FeatureSettingType.LIBRARY_HEALTH_WELLNESS,
  [ShareableContentDomains.UTILITIES]: FeatureSettingType.LIBRARY_UTILITIES,
  [ShareableContentDomains.PARKS_RECREATION]:
    FeatureSettingType.LIBRARY_PARKS_RECREATION,
  [ShareableContentDomains.NATURAL_ENVIRONMENT]:
    FeatureSettingType.LIBRARY_NATURAL_ENVIRONMENT,
};
